import React from "react";

function Servicesflip() {
  return (
    <div className="card" id="about">
      <div className="content">
        <div className="frontFlip">Services</div>
        <div className="backFlip">
          <h5>Web Development</h5>
          <p>
            Call or Text{" "}
            <a href="tel:(860) 287-1409" className="cardFlipLinks">
              (860) 287-1409
            </a>{" "}
            For A Quote, or to Discuss A Project!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Servicesflip;
