import React from "react";

function Aboutflip() {
  return (
    <div className="card" id="about">
      <div className="content">
        <div className="frontFlip">About Us</div>
        <div className="backFlip">
          <p>International Clients & Presence</p>
          <p>@UbiTek we build the website that connects your business to its people!</p>
          <p>
            Email  For A Quote, or to Discuss A Project!
          </p>
          <div className="textSizeShift">
            <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutflip;
