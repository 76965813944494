import React, { useState, useEffect, useMemo } from "react";

function Testimonials() {
  const [index, setIndex] = useState(0);
  const quotesArray = useMemo(
    () => [
      "Built Fast, responsive and sleek design, and a fair price!",
      "Built me a beautiful website.",
      "Rebuilt my 20+ year old site into a sleek modern experience.",
      "Created an effective and clean website to showcase my business!",
      "Updated and improved my companies web presence.",
    ],
    []
  );
  const authorsArray = useMemo(() => ["Kari J.", "Frank C.", "Ann W.","Ben E.","Brad K."], []);
  const [quote, setQuote] = useState(quotesArray[index]);
  const [author, setAuthor] = useState(authorsArray[index]);

  useEffect(() => {
    setQuote(quotesArray[index]);
    setAuthor(authorsArray[index]);
  }, [index, quotesArray, authorsArray]);

  const handleNext = () => {
    setIndex(index === quotesArray.length - 1 ? 0 : index + 1);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? quotesArray.length - 1 : index - 1);
  };

  return (
    <div className="testimonials-container">
      <h3 className="line-behind-text">
        <span>Testimonials</span>
      </h3>
      <p className="testimonials-quote">{quote}</p>
      <p className="testimonials-author">{author}</p>
      <p className="testimonials-bottom-line"></p>
      <div className="arrows-container">
        <div className="arrow prev-arrow" onClick={handlePrev}>
          {"<"}
        </div>
        <div className="arrow next-arrow" onClick={handleNext}>
          {">"}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
