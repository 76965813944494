// Templated
import "./App.css";
// Templated

//Router
import * as React from "react";
import { Link, Outlet } from "react-router-dom";
// Router

import Footer from "./components/footer";
import logo from "./components/img/logo.png";

// Font Awesome Icons: Global Visibility, Individual Import
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagram,
  faGithub,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
library.add(fas, faLinkedin, faInstagram, faGithub, faYoutube);
// Font Awesome Icons: Global Visibility, Individual Import

function App() {
  return (
    <div>
      <nav className="NavbarItems ">
        <div className="left-container">
          <Link to="/">
            <img src={logo} alt="logo" className="co-logo"></img>
          </Link>
          <Link to="/" className="navbar-logo">
            <h1 className="navbar-logo">UbiTek</h1>
          </Link>
        </div>
        <div className="right-container">
          <Link to="/library">
            <button
              type="button"
              className="btn btn-outline-danger btn-lg p-2 m-1"
            >
              Library
            </button>
          </Link>
          <Link to="/contact">
            <button
              type="button"
              className="btn btn-outline-danger btn-lg p-2 m-1"
            >
              Contact
            </button>
          </Link>
          <Link to="/payment">
            <button
              type="button"
              className="btn btn-outline-success btn-lg p-2 m-1"
            >
              Payment
            </button>
          </Link>
        </div>
      </nav>
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
