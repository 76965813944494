import * as React from "react";
import CC from "../img/CC.png";

function Payment() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#F7F7F7",
      }}
    >
      <h2>Make a Payment Below: (Site Integrated Payment Portal Under Development)</h2>
      <form>{/* Payment Portal form goes here */}</form>
      <p>Payment Portal Goes Here</p>
      <div style={{ textAlign: "center" }}>
        <h3>We accept the following credit & debit cards:</h3>
        <img
          style={{
            opacity: "1",
            // backgroundColor: "transparent",
            width: "12.5rem",
            height: "auto",
          }}
          src={CC}
          alt="Accepted Credit Cards"
        />
      </div>
      <p>
        Refund Policy: If you are not satisfied with your purchase, please
        contact us within 30 days to request a refund.
      </p>
    </div>
  );
}

export default Payment;
