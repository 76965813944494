import React from "react";

function TextSizeShift() {
  return (
    <div className="textSizeShiftLibraryBackground">
    
          <div className="textSizeShift">
            <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
          </div>
    </div>
  );
}

export default TextSizeShift;
