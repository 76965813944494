import React from "react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";


const images = [
    { src: require("../../img/boats n skulls.jpg"), title: "boats n skulls", alt: "boats n skulls" }, 
    { src: require("../../img/humpbackWhale.jpeg"), title: "humpbackWhale", alt: "humpbackWhale" },
    { src: require("../../img/oceanHighway.jpeg"), title: "oceanHighway", alt: "oceanHighway" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
    // { src: "", title: "", alt: "" },
];

function LightboxGallery() {
  return (
    <div>
        <div>
            {/* https://www.getlightboxjs.com/docs/SlideshowLightbox/ */}
            <SlideshowLightbox
                lightboxIdentifier="lightbox1"
                framework="next"
                // framework="react"
                images={images}
                className="thumbnails gallery"
                theme="lightbox"
            >
                {images.map((image) => (
                    <img 
                        src={image.src}
                        alt={image.alt}
                        title={image.title}
                        // height={500}
                        // width={500}
                        data-lightboxjs="lightbox1"
                        quality={80}
                        className="p-1"
                        style={{ borderRadius: "1rem"}}
                        key={image.toString()}
                    />
                ))}
            </SlideshowLightbox>
        </div>
    </div>
  );
}

export default LightboxGallery;
