import React from "react";
import AboutFlip from "./aboutFlip.jsx";
import ServicesFlip from "./servicesFlip.jsx";

function Cardflip() {
  
    const flipBackground = {
        backgroundColor: "white",
    };

    return (
        <>
            <div className="container p-3" style={flipBackground}>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <AboutFlip />
                    </div>
                    <div className="col-lg-6">
                        <ServicesFlip />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cardflip;