import * as React from "react";

function ModalBtn() {
  return (
    <>
      {/* Button Modal Trigger Start */}
      <button
        type="button"
        className="btn btn-outline btn-lg p-2"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="applyNow"
      >
        <strong>Apply Now</strong>
      </button>
      {/* Button Modal Trigger End */}

      {/* Modal Content Start */}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-outline" id="applyNow">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Content End */}
    </>
  );
}
export default ModalBtn;
