import * as React from "react";
import "../../styles.css";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import { Link } from "react-router-dom";
// import Ann from "../img/ann.jpg";
// import Blog from "../img/blog.gif";
// import ReactiveTriadsLogo from "../img/reactive-triads-bodywork-logo.jpg";
import ModalBtn from "../libComponents/modalBtn/modalBtn";
import ParaBanner from "../libComponents/parallaxBanner/parallaxBanner";
import  Para from "../libComponents/parallaxBanner/parallaxCTA";
import Cardflip from "../libComponents/cardFlip/cardFlip";
import TextSizeShift from "../libComponents/textSizeShift/textSizeShift"
// import HexGrid from "../libComponents/hexGrid/hexGrid"
import LightboxGallery from "../libComponents/lightboxGallery/lightboxGallery"
import Testimonials from "../libComponents/testimonials/testimonials";

const homeBackground = {
  // backgroundColor: "#444444",
  backgroundColor: "#000",
  // backgroundColor: "#37659D",
};
const homeLinks = {
  textAlign: "center",
  color: "#FFFFFF",
  textDecoration: "none",
};
const libTitle = {
  textAlign: "center",
  padding: "1rem",
};

function Library() {
  return (
    <>
      <div className="libraryTitleBackground" style={homeBackground}>
        <div className="container" style={homeLinks}>
          <h1>Welcome to the Library of Components</h1>
        </div>
      </div>

      <div className="container" style={libTitle}>
        <h1>Modal Button</h1>
        <ModalBtn />
      </div>

      <div className="container" style={libTitle}>
        <h1>Parallax</h1>
      </div>
      <ParaBanner />
      <Para/>

      <div className="container" style={libTitle}>
        <h1>Card Flip</h1>
        <Cardflip />
      </div>
      
      <div className="container" style={libTitle}>
        <h1>Text Size Shift</h1>
        <TextSizeShift />
      </div>

      {/* <div className="container" style={libTitle}>
        <h1>HexGrid</h1>
        <HexGrid />
      </div> */}

      <div className="container" style={libTitle}>
        <h1>Lightbox Gallery</h1>
        <LightboxGallery />
      </div>

      <div className="container" style={libTitle}>
        <Testimonials />
      </div>

    </>
  );
}
export default Library;
