import * as React from "react";

function Privacy() {
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Terms of Service</h2>
      </div>

      <div
        className="container"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>
          These terms of service govern your use of our web development and
          software creation services. By using our services, you agree to be
          bound by these terms. If you do not agree to these terms, you should
          not use our services.
        </p>
        <h5>Use Of Our Services</h5>
        <p>
          You may use our services only for lawful purposes and in accordance
          with these terms. You agree not to use our services:
          <ul>
            <li>
              In any way that violates any applicable federal, state, local or
              international law or regulation.
            </li>
            <li>
              To engage in any activity that could damage, disable, overburden,
              or impair our services or interfere with any other party's use of
              our services.
            </li>
            <li>
              To copy, modify, create derivative works of, publicly display,
              publicly perform, republish, download, store, or transmit any of
              the material on our services, except as expressly permitted by
              these terms.
            </li>
          </ul>
        </p>
        <h5>Intellectual Property</h5>
        <p>
          The services and their entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by us, our licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <h5>Limited License</h5>
        <p>
          We grant you a limited, non-exclusive, non-transferable, and revocable
          license to access and use our services for your personal use. This
          license does not include the right to:
          <ul>
            <li>(i) resell or commercially use our services</li>
            <li>
              (ii) copy, reproduce, distribute, publicly display, or publicly
              perform any part of our services
            </li>
            <li>
              (iii) modify or otherwise make any derivative uses of our
              services, or any portion thereof
            </li>
            <li>
              (iv) use any data mining, robots, or similar data gathering or
              extraction methods
            </li>
            <li>
              (v) download (other than page caching) any portion of our
              services, except as expressly permitted by us.
            </li>
          </ul>
        </p>
        <h5>Changes To Our Services</h5>
        <p>
          We reserve the right to modify, update, or discontinue our services at
          any time without prior notice to you. We are not liable to you or any
          third party for any modification, suspension, or discontinuance of our
          services.
        </p>
        <h5>Disclaimer Of Warranties</h5>
        <p>
          We do not make any warranties or representations about the accuracy or
          completeness of the content on our services or the content of any
          sites linked to our services, and we assume no liability or
          responsibility for any errors, mistakes, or inaccuracies in such
          content.
        </p>
        <h5>Limitation Of Liability</h5>
        <p>
          In no event shall we be liable to you or any third party for any
          indirect, consequential, incidental, punitive, or special damages,
          including but not limited to loss of profits, data, or other
          intangible losses arising out of or in connection with these terms or
          your use of our services.
        </p>
        <h5>Indemnification</h5>
        <p>
          You agree to indemnify and hold us harmless from any claims,
          liabilities, damages, and expenses (including attorneys' fees) arising
          out of or in connection with your use of our services, your violation
          of these terms, or your violation of any rights of another.
        </p>
        <h5>Governing Law</h5>
        <p>
          These terms and any dispute arising out of or related to these terms
          or our services shall be governed by and construed in accordance with
          the laws of the state of [insert state] without giving effect to any
          principles of conflicts of law.
        </p>
        <p>
          Contact us If you have any questions or concerns about our privacy
          policy, please contact us at{" "}
          <i>
            <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
          </i>
        </p>

        <p>Last updated: March 25th, 2023</p>
      </div>
    </>
  );
}

export default Privacy;
