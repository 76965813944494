import * as React from "react";
import LeMe from "../img/FullSizeRender.heic.jpeg";
import contactSplash from "../img/contactSplash.jpg";

function Contact() {
  const contactStyle = {
    marginLeft: "1.25vw",
    marginRight: "1.25vw",
    padding: "5rem",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row", // added property to display image and text next to each other horizontally
  };
  const contactNate = {
    height: "auto",
    width: "35vh",
    borderRadius: "5px",
    boxShadow: "5px 5px 8px 5px rgba(0, 0, 0, 0.449)",
  };
  const contactTitle = {
    textAlign: "center",
    padding: "3rem",
    marginTop: "0rem",
    color: "ivory",
  };
  const background = {
    backgroundImage: `url(${contactSplash})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  };
  const textStyle = {
    color: "ivory",
  };
  const links = {
    color: "#FFF",
    textDecoration: "none",
  };

  // added media query to adjust flex direction for smaller screen sizes
  // const mediaQuery = "@media (max-width: 1100px)";
  const mediaQuery = "@media (max-width: 768px)";
  const contactStyleMobile = {
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <>
      <div style={background}>
        <h1 style={contactTitle}>Founder</h1>
        <div className="row" style={{ ...contactStyle, ...{ [mediaQuery]: contactStyleMobile } }}>
          <div className="col-md-6">
            <img src={LeMe} style={contactNate} alt="nate" />
          </div>
          <div className="col-md-6">
            <em>
              <h4 style={textStyle}>Nathan Watkins-Hoagland</h4>
            </em>
            <div className="textSizeShift">
              <i>
                <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
              </i>
            </div>
            <p style={textStyle}>
              <a href="tel:860.287.1409" style={links}>
                (860) 287.1409
              </a>{" "}
              <br /> Portland, OR 97201
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
