import React from "react";

// Pulls from the global css styles sheet

function ParaBanner() {
    return (
        <>
            {/* Parallax */}
            <div className="jumbotron section" id="parallaxHome">
                <h1 className="display-3 paraBlur">
                    Opacity Shader
                </h1>
                <p>
                    <a class="btn btn-warning btn-lg btn-md p-2" href="/" role="button">Le Home Button</a> 
                </p>
            </div>
            {/* Parallax  */}
        </>
    );
}

export default ParaBanner;