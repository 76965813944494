import * as React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import redWindow from "../img/redWindow.jpeg";
import northernLights from "../img/woodTable.jpeg";

// const homeBackground = {
//   backgroundColor: "#37659D",
// };

// const homeLinks = {
//   textAlign: "center",
//   color: "#FFFFFF",
// };

const servicesSection = {
  backgroundColor: "#FFFFFF",
  padding: "50px 0",
};

const serviceCard = {
  backgroundColor: "#F5F5F5",
  borderRadius: "10px",
  padding: "20px",
  marginBottom: "30px",
};

// Parallax
const homeParallaxCTA = {
  // backgroundImage: `url(
  // 	"https://www.pexels.com/photo/black-and-white-turtle-1618606/"
  // )`,
  backgroundImage: `url(${redWindow})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  height: "75vh",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const homeParallaxLib = {
  // backgroundImage: `url(
  // 	"https://www.pexels.com/photo/black-and-white-turtle-1618606/"
  // )`,
  backgroundImage: `url(${northernLights})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  height: "50vh",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
// Parallax
const estimateBtn = {
  opacity: 0.85,
};

function Home() {
  return (
    <>
      {/* <div style={homeBackground}>
        <div className="container" style={homeLinks}>
          <h1>Site Under Development</h1>
         
          <p>Skip the rest, come to the best</p>
          <p>Discover the possible</p>
          <p>Define, and refine your brand</p>
          <p>Reach Towards Excellence</p>
          <p>Chase Perfection, Attain Excellence!</p>
          <p>Reach Towards Excellence, Contact Today!</p>
        </div>
      </div> */}

      <div style={homeParallaxCTA}>
        <Link to="/contact">
          {/* <a href="/contact"> */}
          {/* <a href="https://example.com/contact"> */}
          <Button
            className="btn btn-danger btn-lg p-3"
            type="button"
            name="button"
            style={estimateBtn}
          >
            <h1>Skip the Rest, Come to the Best</h1>
            {/* <h1>Seize Your Tomorrow!</h1> */}
            <h1>Contact Us Today!</h1>
          </Button>
          {/* </a> */}
        </Link>
      </div>

      <div style={servicesSection}>
        <div className="container">
          <h2>Our Services</h2>
          <div className="row">
            <div className="col">
              <div style={serviceCard}>
                <h3>Custom Software</h3>
                <p>
                  We develop custom software solutions tailored to your specific
                  needs.
                </p>
              </div>
              <div style={serviceCard}>
                <h3>Mobile Apps</h3>
                <p>
                  We can help you create engaging and functional mobile apps for
                  iOS and Android platforms.
                </p>
              </div>
            </div>
            <div className="col">
              <div style={serviceCard}>
                <h3>Websites</h3>
                <p>
                  We can build responsive and user-friendly websites to help you
                  establish a strong online presence.
                </p>
              </div>
              <div style={serviceCard}>
                <h3>E-commerce</h3>
                <p>
                  We can help you set up an online store that is secure and easy
                  to use for your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={homeParallaxLib}>
        <Link to="/library">
          {/* <a href="/library"> */}
          {/* <a href="https://example.com/contact"> */}
          <Button
            className="btn btn-danger btn-lg p-5"
            type="button"
            name="button"
            style={estimateBtn}
          >
            <h1>Check Out Our Library of Components</h1>
          </Button>
          {/* </a> */}
        </Link>
      </div>
    </>
  );
}

export default Home;
