import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Whale from "../../img/humpbackWhale.jpeg";

// Everything is done in this file, it doesn't pull anything from the global css styles sheet

function ParallaxCTA() {
  // Parallax
  const parallaxCTA = {
    // backgroundImage: `url(
    // 	"https://www.pexels.com/photo/black-and-white-turtle-1618606/"
    // )`,
    backgroundImage: `url(${Whale})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "50vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  };
  // Parallax
  const estimateBtn = {
    opacity: 0.8,
  };

  return (
    <>
      <div style={parallaxCTA}>
        <Link to="/contact">
          {/* <a href="/contact"> */}
          {/* <a href="https://example.com/contact"> */}
          <Button
            className="btn btn-success btn-lg p-5"
            type="button"
            name="button"
            style={estimateBtn}
          >
            <h1>Free Quotes</h1>
          </Button>
          {/* </a> */}
        </Link>
      </div>
    </>
  );
}

export default ParallaxCTA;
