import * as React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const footer = {
  display: "flex",
  // background: "#5E87A1",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  padding: "1rem",
};

const links = {
  color: "#FFF",
  textDecoration: "none",
};

const socialLinks = {
  color: "#fff",
  padding: ".25rem",
  display: "inline-block",
};

export default function Footer() {
  return (
    <div className="footerBackground" style={footer}>
      <p style={{ color: "#fff", marginBottom: "1rem" }}>
        Hollywood FL |{" "}
        <Link to="tel:8602871409" style={links}>
          <FontAwesomeIcon icon={faPhone} /> (860) 287.1409
        </Link>
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="https://github.com/WiseFool92" style={socialLinks}>
          <FontAwesomeIcon icon="fa-brands fa-github" />
        </a>
        <a
          href="https://www.linkedin.com/in/nathan-watkins-hoagland/"
          style={socialLinks}
        >
          <FontAwesomeIcon icon="fa-brands fa-linkedin" />
        </a>
        <a href="https://www.instagram.com/mr.supernova/" style={socialLinks}>
          <FontAwesomeIcon icon="fa-brands fa-instagram" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCo4USBh5QCeNpv9nvDYoX8Q?view_as=subscriber"
          style={socialLinks}
        >
          <FontAwesomeIcon icon="fa-brands fa-youtube" />
        </a>
      </div>
      <p style={{ color: "#FFFFFF", marginTop: "1rem" }}>
        &copy; 2022-{new Date().getFullYear()} | UbiTek LLC
        <br />
        All Right Reserved |
        <Link to="/terms" style={links}>
          <span style={links}> Terms of Service |</span>
        </Link>
        <Link to="/privacy" style={links}>
          <span> Privacy Policy</span>
        </Link>
      </p>
    </div>
  );
}
