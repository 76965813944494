import * as React from "react";

function Privacy() {
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Privacy Policy</h2>
      </div>

    <div
        className="container"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
         <p>
          This privacy policy will help you understand how we use and protect
          the data that you provide to us when you use our web development and
          software creation services. We reserve the right to change this policy
          at any time, and will indicate the date of the most recent revisions
          at the bottom of this page. We encourage you to review this policy
          whenever you access our services to ensure that you understand how any
          personal information you provide will be used.
        </p>
        <h5>What Data We Collect</h5>
        <div>
          <p>
            We may collect the following information from you when you use our
            services: Personal information such as your name and email address.
            Non-personal information such as your IP address and the type of
            browser you are using. Other information that you voluntarily
            provide to us when using our services.
          </p>
        </div>

        <h5>How We Use Your Data</h5>
        <p>
          We use your data to provide and improve our services, and to
          communicate with you about your use of our services. We may also use
          your data for the following purposes: To personalize your experience
          when using our services. To analyze how our services are used and to
          improve their functionality. To send you promotional emails about new
          services, special offers, or other information that we think you may
          find interesting. To comply with legal and regulatory requirements.
          How we protect your data We take the security of your data seriously
          and take measures to protect it from unauthorized access or
          disclosure. We use industry-standard encryption protocols and secure
          servers to store your data.
        </p>
        <h5>Sharing Your Data</h5>
        <p>
          We will not share your data with third parties except as described in
          this policy or as required by law. We may share your data with service
          providers who perform functions on our behalf, such as hosting our
          website or analyzing how our services are used. These service
          providers are bound by confidentiality agreements and are prohibited
          from using your data for any other purpose.
        </p>
        <h5>Cookies</h5>
        <p>
          We use cookies to enhance your experience when using our services.
          Cookies are small text files that are placed on your computer or
          mobile device when you access our website. We use both session
          cookies, which expire when you close your browser, and persistent
          cookies, which remain on your device until they expire or you delete
          them. You can disable cookies through your browser settings, but this
          may affect your ability to use our services.
        </p>
        <h5>Links To Other Websites</h5>
        <p>
          Our services may contain links to other websites that are not owned or
          operated by us. We are not responsible for the privacy practices of
          these websites and encourage you to review their privacy policies
          before providing any personal information.
        </p>
        <h5>Children's Privacy</h5>
        <p>
          Our services are not intended for use by children under the age of 13,
          and we do not knowingly collect personal information from children
          under the age of 13. If you are a parent or guardian and believe that
          your child has provided us with personal information, please contact
          us immediately at{" "}
          <i>
            <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
          </i>{" "}
          and we will take steps to remove such information from our systems.
        </p>
        <p>
          Contact us If you have any questions or concerns about our privacy
          policy, please contact us at{" "}
          <i>
            <a href="mailto:ubitek.info@gmail.com">ubitek.info@gmail.com</a>
          </i>
        </p>
        <p>Last updated: March 25th, 2023</p>
      </div>
    </>
  );
}

export default Privacy;
